import React from 'react';
import { useOcContext } from '@lib/ocContext';
import cx from 'classnames';
import { Link } from 'gatsby';

const EntryFormFooter = () => {
  const { ocState, dispatch } = useOcContext();

  // create handle function to loop over and focus on every input field and select field
  const handleFocus = () => {
    const inputs = document.querySelectorAll('input, select, checkbox');
    inputs.forEach((input) => {
      input.focus();
      input.blur();
    });
    // if step 3 or 4 then update current step
    if (ocState.currentStep === 3 || ocState.currentStep === 4) {
      dispatch({ type: 'updateCurrentStep', payload: ocState.currentStep + 1 });
    }
    //scrtoll to top
    window.scroll(0, 0);
  };

  const handleBack = () => {
    dispatch({ type: 'updateCurrentStep', payload: ocState.currentStep - 1 });
    //scrtoll to top
    window.scroll(0, 0);
  };

  const handleSubmit = async () => {
    const response = await fetch('/.netlify/functions/send-oc-entry', {
      method: 'POST',
      body: JSON.stringify(ocState),
    });

    if (response.status === 200) {
      localStorage.removeItem('ocState');
      dispatch({ type: 'resetState' });
    }

    if (response.status === 400) {
      console.log('error');
    }
  };

  return (
    <div className='flex justify-between mt-20'>
      {ocState?.currentStep !== 1 ? (
        <button onClick={handleBack} className='btn-entry-nav'>
          Back
        </button>
      ) : (
        <Link to='/contest' className='btn-entry-nav'>
          Back
        </Link>
      )}
      {ocState.currentStep !== 4 ? (
        <button
          type={ocState.currentStep === 1 || 2 ? 'submit' : 'button'}
          form={
            ocState.currentStep === 1
              ? 'contact-details-form'
              : ocState.currentStep === 2
              ? 'billing-details-form'
              : null
          }
          onClick={handleFocus}
          className='btn-entry-nav'>
          Next
        </button>
      ) : (
        <Link
          to='/contest/original-confirmation'
          onClick={handleSubmit}
          className='btn-entry-nav'>
          Submit Entry
        </Link>
      )}
    </div>
  );
};

export default EntryFormFooter;

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import OriginalStepsContainer from '../../components/entryForm/original/originalStepsContainer';
import { StateProvider } from '../../lib/ocContext';

import EntryHeader from '../../components/nav/entryHeader';
import EntryFormFooter from '../../components/entryForm/entryFormFooter';

import '@css/tailwind.css';
import '@css/main.css';

const OriginalCompositionPage = () => {
  // start at top of the page
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <StateProvider>
      <AnimatePresence exitBeforeEnter>
        <motion.div className='entry-form-container w-full h-full min-h-screen pb-20'>
          <EntryHeader />
          <OriginalStepsContainer />
          <EntryFormFooter />
        </motion.div>
      </AnimatePresence>
    </StateProvider>
  );
};

export default OriginalCompositionPage;

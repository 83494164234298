import React, { useState } from 'react';
import { useOcContext } from '@lib/ocContext';
import { motion, AnimatePresence } from 'framer-motion';

const EntryDetails = () => {
  const { ocState, dispatch } = useOcContext();
  const [entryDetails, setEntryDetails] = useState(ocState.entryDetails);

  const [isGroup, setIsGroup] = useState(false);

  const handleInputChange = (e) => {
    const previousState = entryDetails;
    const { name, value } = e.target;
    previousState[name] = value;
    setEntryDetails(previousState);
    dispatch({ type: 'updateEntryDetails', payload: previousState });
  };

  const handleGroupChange = (e) => {
    setIsGroup(e.target.checked);
    const previousState = entryDetails;
    const { name, checked } = e.target;
    previousState[name] = checked;
    setEntryDetails(previousState);
    dispatch({ type: 'updateEntryDetails', payload: previousState });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container overflow-hidden'>
        <div className='xl:grid grid-cols-12'>
          <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
            <h4 className='blockH4 mb-2'>Entry Details</h4>
          </div>
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form action=''>
              <h5 className='blockH6 mb-9'>Tutor/Teacher Information</h5>
              <div class=' entryInputContainer group'>
                <input
                  name='originalTutorName'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.entryDetails?.originalTutorName}
                />
                <label
                  for='originalTutorName'
                  className='entryLabel pointer-events-none '>
                  Tutor/Teacher's Full Name
                </label>
              </div>
              <div class=' entryInputContainer group'>
                <input
                  name='originalTutorPhone'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.entryDetails?.originalTutorPhone}
                />
                <label
                  for='originalTutorPhone'
                  className='entryLabel pointer-events-none '>
                  Tutor/Teacher's Phone Number
                </label>
              </div>
              <div class=' entryInputContainer group'>
                <input
                  name='originalTutorEmail'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.entryDetails?.originalTutorEmail}
                />
                <label
                  for='originalTutorEmail'
                  className='entryLabel pointer-events-none '>
                  Tutor/Teacher's Email Address
                </label>
              </div>
              <h5 className='blockH6 mb-9 mt-20'>Performance Information</h5>
              <div class=' entryInputContainer group'>
                <input
                  name='originalPerformanceTitle'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.entryDetails?.originalPerformanceTitle}
                />
                <label
                  for='originalPerformanceTitle'
                  className='entryLabel pointer-events-none '>
                  Title of Work (Full Name)
                </label>
              </div>
              <div className='mb-5'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    name='originalGroupName'
                    onChange={handleGroupChange}
                    value={ocState?.entryDetails?.originalGroupName}
                    checked={ocState?.entryDetails?.originalGroupName}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    This work will be performed by a group entering the
                    Instrumental Performance section of the Contest.
                  </span>
                </label>
              </div>
              <AnimatePresence>
                {entryDetails?.originalGroupName && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 50 }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                    // className="overflow-hidden"
                  >
                    <div class=' entryInputContainer  group ml-[42px] xl:ml-[51px]  '>
                      <input
                        name='originalGroupNameField'
                        type='text'
                        placeholder=' '
                        className='entryInput peer focus:border-sand border-sand'
                        onChange={handleInputChange}
                        value={ocState?.entryDetails?.originalGroupNameField}
                      />
                      <label
                        for='originalGroupNameField'
                        className='entryLabel pointer-events-none  '>
                        Name of group performing this work
                      </label>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EntryDetails;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useOcContext } from '@lib/ocContext';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const ContactDetails = () => {
  const { ocState, dispatch } = useOcContext();
  const [contactDetails, setContactDetails] = useState(ocState.contactDetails);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newContactDetails = { ...contactDetails, [name]: value };
    dispatch({ type: 'updateContactDetails', payload: newContactDetails });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    console.log('submitted');
    // update current step
    dispatch({ type: 'updateCurrentStep', payload: 2 });
  };

  const composerRegions = [
    'Northland (Whangārei)',
    'Auckland',
    'Waikato',
    'Bay of Plenty (Tauranga)',
    'Taranaki (New Plymouth)',
    'East Coast (Gisborne)',
    'Hawke’s Bay (Napier)',
    'Whanganui',
    'Manawatū (Palmerston North)',
    'Wellington',
    'Nelson-Marlborough',
    'Canterbury (Christchurch)',
    'Otago (Dunedin)',
    'Southland (Invercargill)',
  ];

  const composerYears = [
    'Year 7',
    'Year 8',
    'Year 9',
    'Year 10',
    'Year 11',
    'Year 12',
    'Year 13',
  ];

  // create array of stringed numbers from 8 to 21
  const composerAges = Array.from({ length: 14 }, (_, i) => i + 8);

  const composerEthnicities = [
    'NZ European',
    'Māori',
    'Pasifika',
    'Asian',
    'Middle Eastern',
    'Latin American',
    'African',
    'Other',
  ];

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
            <h4 className='blockH4 mb-2'>Contact Details</h4>
            <p>This will be the details of the composer</p>
          </div>
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='contact-details-form'>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.composerFullName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.composerFullName?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerFullName}
                  {...register('composerFullName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerFullName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.composerFullName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerPreferredName?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerPreferredName?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerPreferredName}
                  {...register('composerPreferredName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerPreferredName'
                  className='entryLabel pointer-events-none '>
                  What should we call you?*
                </label>
                {errors.composerPreferredName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {ocState?.contactDetails?.composerRegion && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    District/Region*
                  </motion.span>
                )}

                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.composerRegion?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerRegion?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerRegion}
                  {...register('composerRegion', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    District/Region*
                  </option>
                  {composerRegions.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.composerRegion?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerSchool?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerSchool?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerSchool}
                  {...register('composerSchool', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerSchool'
                  className='entryLabel pointer-events-none '>
                  School (Full Name)*
                </label>
                {errors.composerSchool?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {ocState?.contactDetails?.composerSchoolYear && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    What year are you currently at school?*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.composerSchoolYear?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerSchoolYear?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerSchoolYear}
                  {...register('composerSchoolYear', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    What year are you currently at school?*
                  </option>
                  {composerYears.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.composerSchool?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {ocState?.contactDetails?.composerSchoolAge && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    How old will you be as of 1 July 2024*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.composerSchoolAge?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerSchoolAge?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerSchoolAge}
                  {...register('composerSchoolAge', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    How old will you be as of 1 July 2024*
                  </option>
                  {composerAges.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.composerSchoolAge?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {ocState?.contactDetails?.composerEthnicities && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    What ethnicities do you most identify with?*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.composerEthnicities?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerEthnicities?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerEthnicities}
                  {...register('composerEthnicities', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    What ethnicities do you most identify with?*
                  </option>
                  {composerEthnicities.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.composerEthnicities?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  name='composerOtherEthnicities'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.contactDetails?.composerOtherEthnicities}
                />
                <label
                  for='composerOtherEthnicities'
                  className='entryLabel pointer-events-none '>
                  Other ethnicities (if applicable)
                </label>
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerAddress?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerAddress?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerAddress}
                  {...register('composerAddress', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerAddress'
                  className='entryLabel pointer-events-none '>
                  Address*
                </label>
                {errors.composerAddress?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerSuburb?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerSuburb?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerSuburb}
                  {...register('composerSuburb', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerSuburb'
                  className='entryLabel pointer-events-none '>
                  Suburb*
                </label>
                {errors.composerSuburb?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerCity?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerCity?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerCity}
                  {...register('composerCity', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerCity'
                  className='entryLabel pointer-events-none '>
                  City*
                </label>
                {errors.composerCity?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerTown?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerTown?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerTown}
                  {...register('composerTown', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerTown'
                  className='entryLabel pointer-events-none '>
                  Town*
                </label>
                {errors.composerTown?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='number'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerPostcode?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerPostcode?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerPostcode}
                  {...register('composerPostcode', {
                    onChange: handleInputChange,
                    required: true,
                    valueAsNumber: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerPostcode'
                  className='entryLabel pointer-events-none '>
                  Postcode*
                </label>
                {errors.composerPostcode?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerPhone?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerPhone?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerPhone}
                  {...register('composerPhone', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerPhone'
                  className='entryLabel pointer-events-none '>
                  Mobile Phone*
                </label>
                {errors.composerPhone?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.composerEmail?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.composerEmail?.type === 'required',
                  })}
                  value={ocState?.contactDetails?.composerEmail}
                  {...register('composerEmail', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerEmail'
                  className='entryLabel pointer-events-none '>
                  Email Address*
                </label>
                {errors.composerEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactDetails;

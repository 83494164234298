import React from 'react';
import { useOcContext } from '@lib/ocContext';
import { motion, AnimatePresence } from 'framer-motion';

const OriginalEntrySummary = () => {
  const { ocState } = useOcContext();
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='flex justify-between blockH6'>
          <h4>1 x Original Composition</h4>
          {/* <p>$100.00</p> */}
        </div>
        <div className='lg:grid grid-cols-12 mt-6 space-y-8 lg:space-y-0 pb-10 sm:pb-20 xl:pb-36 pt-4 border-opacity-[30%] border-t border-sand'>
          <div className='col-start-1 col-end-4 space-y-2'>
            <h6>Contact: </h6>
            <p>{ocState?.contactDetails?.composerFullName}</p>
            <p>{ocState?.contactDetails?.composerRegion}</p>
            <p>{ocState?.contactDetails?.composerSchool}</p>
            <p>{ocState?.contactDetails?.composerSchoolYear}</p>
            <p>{ocState?.contactDetails?.composerSchoolAge} Years Old</p>
          </div>
          <div className='col-start-5 col-end-8 space-y-2'>
            <h6>Teacher/Tutor: </h6>
            <p>{ocState?.entryDetails?.originalTutorName}</p>
            <p>{ocState?.entryDetails?.originalTutorPhone}</p>
            <p>{ocState?.entryDetails?.originalTutorEmail}</p>
          </div>
          <div className='col-start-9 col-end-12 space-y-2'>
            <h6>Music: </h6>
            <p>{ocState?.entryDetails?.originalPerformanceTitle}</p>
          </div>
        </div>
        <div className='flex justify-end pt-12 border-t border-sand'>
          {/* <p className="space-x-20 blockH6">
            <span>Total:</span>
            <span>$100.00</span>
          </p> */}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default OriginalEntrySummary;

import React from 'react';
import { useOcContext } from '@lib/ocContext';

import ContactDetails from './contactDetails';
import BillingDetails from './billingDetails';
import EntryDetails from './entryDetails';
import OriginalEntrySummary from './entrySummary';
import OriginalConfirmation from './confirmation';

const OriginalStepsContainer = () => {
  const { ocState } = useOcContext();

  return (
    <div className='entry-form-content'>
      {(() => {
        switch (ocState.currentStep) {
          case 1:
            return <ContactDetails />;
          case 2:
            return <BillingDetails />;
          case 3:
            return <EntryDetails />;
          case 4:
            return <OriginalEntrySummary />;

          default:
            return <ContactDetails />;
        }
      })()}
    </div>
  );
};

export default OriginalStepsContainer;

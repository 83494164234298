import React, { useReducer, createContext, useEffect } from 'react';

const initialState = {
  contactDetails: {
    // composerFullName: "",
    // composerPreferredName: "",
    // composerRegion: "",
    // composerSchool: "",
    // composerSchoolYear: "",
    // composerSchoolAge: "",
    // composerEthnicities: "",
    // composerOtherEthnicities: "",
    // composerAddress: "",
    // composerSuburb: "",
    // composerCity: "",
    // composerTown: "",
    // composerPostcode: "",
    // composerPhone: "",
    // composerEmail: "",
  },
  billingDetails: {
    // originalBillingName: "",
    // originalBillingSchool: "",
    // originalBillingEmail: "",
    // originalBillingAddress: "",
    // originalBillingSuburb: "",
    // originalBillingCity: "",
    // originalBillingTown: "",
    // originalBillingPostcode: "",
  },
  entryDetails: {},
  rulesAndConditions: false,
  currentStep: 1,
};

export const OcContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadState':
      return action.payload;
    case 'updateContactDetails':
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          ...action.payload,
        },
      };
    case 'updateBillingDetails':
      return {
        ...state,
        billingDetails: action.payload,
      };
    case 'updateEntryDetails':
      return {
        ...state,
        entryDetails: action.payload,
      };
    case 'updateRulesAndConditions':
      return {
        ...state,
        rulesAndConditions: action.payload,
      };
    case 'updateCurrentStep':
      return {
        ...state,
        currentStep: action.payload,
      };
    // reset the state to the initialState
    case 'resetState':
      return initialState;
    default:
      throw new Error('Invalid action type');
  }
};

const StateProvider = ({ children }) => {
  const [ocState, dispatch] = useReducer(reducer, initialState);

  // load state from localstorage on first render
  useEffect(() => {
    const localState = localStorage.getItem('ocState');
    if (localState) {
      dispatch({ type: 'loadState', payload: JSON.parse(localState) });
    }
  }, []);

  // update browser localstorage as state changes
  useEffect(() => {
    localStorage.setItem('ocState', JSON.stringify(ocState));
  }, [ocState]);

  return (
    <OcContext.Provider value={{ ocState, dispatch }}>
      {children}
    </OcContext.Provider>
  );
};

const useOcContext = () => {
  const context = React.useContext(OcContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

export { StateProvider, useOcContext };
